import {Icon} from '@iconify/react';
import * as Sentry from '@sentry/nextjs';
import {ColorModeProvider} from '@theme-ui/color-modes';
import {NextPageContext, NextPage} from 'next';
import NextErrorComponent, {ErrorProps} from 'next/error';
import {Heading, ThemeProvider, Flex} from 'theme-ui';

import theme from 'styles/theme';

import {ICONS} from '../constants';

const statusCodes: {[code: number]: string} = {
  400: 'Bad Request',
  404: 'This page could not be found',
  405: 'Method Not Allowed',
  500: 'Internal Server Error',
};

interface Props extends ErrorProps {
  hasGetInitialPropsRun?: boolean;
  err?: Error;
}

const CustomError = ({statusCode, title}: ErrorProps) => {
  const errorTitle = statusCodes[statusCode] ?? title ?? 'An unexpected error has occurred';

  return (
    <Flex
      sx={{
        height: '70vh',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'text',
        background: 'background',
      }}
    >
      <div>
        <Icon icon={ICONS.error} width={40} height={40} />
      </div>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {statusCode && (
          <Heading
            sx={{
              fontSize: 3,
              padding: 3,
              fontWeight: 300,
            }}
          >
            {statusCode}
          </Heading>
        )}
        <Heading
          sx={{
            fontSize: 1,
            fontWeight: 300,
          }}
        >
          {errorTitle}
        </Heading>
      </Flex>
    </Flex>
  );
};

const CustomErrorPage: NextPage<Props> = ({statusCode, hasGetInitialPropsRun, err}: Props) => {
  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Sentry.captureException(err);
    // Flushing is not required in this case as it only happens on the client
  }

  return (
    <ThemeProvider theme={theme}>
      <ColorModeProvider>
        <CustomError statusCode={statusCode} />
      </ColorModeProvider>
    </ThemeProvider>
  );
};

CustomErrorPage.getInitialProps = async (context: NextPageContext) => {
  const errorInitialProps: Props = await NextErrorComponent.getInitialProps(context);

  const {res, err, asPath} = context;

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  errorInitialProps.hasGetInitialPropsRun = true;

  // Returning early because we don't want to log 404 errors to Sentry.
  if (res?.statusCode === 404) {
    return errorInitialProps;
  }

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (err) {
    Sentry.captureException(err);

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    await Sentry.flush(2000);

    return errorInitialProps;
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  Sentry.captureException(new Error(`_error.js getInitialProps missing data at path: ${asPath}`));
  await Sentry.flush(2000);

  return errorInitialProps;
};

export default CustomErrorPage;
